import React, { Component } from 'react'
import * as Constants from '../constants'

import NavBarOrg from '../lib/components/NavBarOrg'
import TextScreen from '../lib/components/screens/TextScreen'
import OrganizerScreen from '../lib/components/screens/OrganizerScreen'

import './EclipseStyles/main.scss'
import SharedContext from '../lib/sharedContext'
import { Navigate } from 'react-router-dom'

export default class GenericError extends Component{
    constructor(props){
        super(props)
        this.state = {
            loading: true,
            redirect: false
        }

        setTimeout(() => this.setState({ redirect: true }), 5000)
    }

    render(){
        return (
            <SharedContext.Provider value={{data: this.state.data, setData: null}}>
                <div className='whiteText'>
                    <NavBarOrg/>
                    <TextScreen background="#202030" style={{paddingTop: '4em'}}>
                        {'# Oya oya'}
                        {'Such a cute takodachi made its way over here.'}
                        {'Still, how did you get here? Pretty sure you are not supposed to be here. Let me warp ya back from the void.'}
                    </TextScreen>

                    { this.state.redirect && <Navigate to='/' replace={true}/> }
                </div>
            </SharedContext.Provider>
        )
    }
}
