import React from 'react'
import './main.scss'

function getSubtitle(prop){
    if(Array.isArray(prop)) return prop[Math.floor(prop.length * Math.random())]
    else return prop
}

const OrganizerCard = (props) => {
    const { data } = props
    if(data.retire) return null
    return (
        <div className={`organizerCard ${props.className || ''}`}>
            {
                data.knobhead ? <>
                    <div className="image flippy" style={{backgroundImage: `url(${data.image})`}}/>
                    <div className="image backdrop">
                        <span>
                        Come to the secret club at <code style={{fontStyle: 'monospace'}}>/wah</code>. The code is 910846
                        </span>
                    </div>
                </> : <div className="image" style={{backgroundImage: `url(${data.image})`}}/>
            }
            <div className="info">
                <div className="nameplate">
                    <span>{data.name}</span>
                    <span>{getSubtitle(data.subtitle)}</span>
                </div>
                <div className="contacts"></div>
            </div>
        </div>
    )
}

const Organizers = (props) => {
    const { data } = props
    return (
        <div className="organizers">
            {data.map((x,i) => <OrganizerCard key={`organizer_${i}`} className={`${x.knobhead ? 'knob' : ''}`}data={x}/>)}
        </div>
    )
}

export default Organizers