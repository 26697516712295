import React from 'react'
import './style.scss'
import { ErrorMessage, Field } from 'formik'

function InputField(props){
    let elements = [
        <label htmlFor={props.name}>{props.children}</label>,
        <Field
            disabled={props.disabled}
            id={props.name}
            name={props.name}
            type={props.type}
            value={props.value}
            component={props.component || 'input'}
            step={props.step}
            min={props.min}
            max={props.max}
            onClick={props.onClick}/>,
        props.extra
    ]
    return (
        <div className={'inputKeyPair ' + (props.className || '')}>
            {props.type === "checkbox" ? elements.reverse() : elements}
            <ErrorMessage name={props.name} component="div"/>
        </div>
    )
}

export default InputField