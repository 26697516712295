import React, { Component } from 'react'

function getSubtitle(prop){
    if(Array.isArray(prop)) return prop[Math.floor(prop.length * Math.random())]
    else return prop
}

export function OrganizerCard (props) {
    const {
        image,
        person,
        subtitle,
    } = props.data || {}

    return (
        <div className={`organizerCard ${props.className || ''}`}>
            {
                <div className="image" style={{backgroundImage: `url(${image})`}}/>
            }
            <div className="info">
                <div className="nameplate">
                    <span>{person}</span>
                    <span>{getSubtitle(subtitle)}</span>
                </div>
                <div className="contacts"></div>
            </div>
        </div>
    )
}

export default class OrganizerGroup extends Component{
    constructor(props){
        super(props)
        this.state = {
            state: 0, //0 = working, 1 = success, 2 = failed
            data: null,
            error: null
        }
    }
    componentDidMount(){
    }
    render(){
        return this.props.source ? (
            <div className={`organizers`}>
                {this.props.source.map((x, i) => 
                    (x !== null ? <OrganizerCard key={`organizerCard_${i}_${x.person}`} data={x}/> : x)
                )}
            </div>
        ) : null
    }
}