import React, { Component } from 'react'
import * as Constants from '../constants'

import NavBarOrg from '../lib/components/NavBarOrg'
import TextScreen from '../lib/components/screens/TextScreen'
//import OrganizerScreen from '../lib/components/screens/OrganizerScreen'
import OrganizerGroup from '../lib/components/OrganizerGroup'

import { initializeApp } from 'firebase/app'
import { getDatabase, get, child, ref as dbRef } from 'firebase/database'
import { fireapp_settings } from '../constants'
//import { unpackPresentations } from '../lib/util'

import './EclipseStyles/main.scss'
import './EclipseStyles/ddlc.scss'
import SharedContext from '../lib/sharedContext'
import { Spinner } from '@vechaiui/react' // eslint-disable-line

const SlowText = (props) => {
    if (props.error) return props.errorChildren || 'Ah... Nastala chyba...'
    if (props.value) return props.children
    else return (<Spinner size='xs'/>)
}

export default class Organizatori extends Component{
    constructor(props){
        super(props)
        this.state = {
            loading: true,
            organizers: {
                old: []
            }
        }
    }

    render(){
        return (
            <SharedContext.Provider value={{data: this.state.data, setData: null}}>
                <div className='whiteText'>
                    <NavBarOrg/>
                    {/*<TextScreen background="#202030" style={{paddingTop: '4em'}}>
                        {'# Historičtí organizátoři'}
                        <OrganizerScreen data={Constants.old_organizers}/>
                        {Constants.text.old_orgText}
                    </TextScreen>*/}
                    <TextScreen background="#202030" style={{paddingTop: '4em'}}>
                        {'# Historičtí organizátoři'}
                        { <SlowText value={!this.state.loading} error={this.state.error}>
                            { (() => {
                                if (this.state.organizers.old.length === 0) return 'Organizátoři budou postupně zveřejňováni.'
                                return (<OrganizerGroup tiny source={this.state.organizers.old}/>)
                            })() }
                        </SlowText> }
                        {Constants.text.old_orgText}
                    </TextScreen>
                </div>
            </SharedContext.Provider>
        )
    }
    componentDidMount(){
		window.addEventListener('popstate', this.locationIsUpdated)
		let url = window.location.hash.substring(1)
        this.setState({url})

        const fireapp = initializeApp(fireapp_settings)
        const database = getDatabase(fireapp)

        get(child(dbRef(database), 'organizers')).then(snapshot => {
            const organizers = Object.values(snapshot.val())
            this.setState({ ...this.state, loading: false, organizers: {
                old: organizers.filter(x => !x.hidden && x.type === 'OLD_ORGANIZER')
            } })

        }).catch(err => {
            console.error(err)
            this.setState({
                loading: false,
                error: err
            })
        })
	}
}
