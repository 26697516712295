import React, { Component } from 'react'
import SharedContext from '../../sharedContext'
import './main.scss'
import './magic.scss'

import { fireapp_settings } from '../../../constants'
import { initializeApp } from 'firebase/app'
import { getAuth, onAuthStateChanged } from 'firebase/auth'

export default class TitleScreen extends Component{
    constructor(props){
        super(props)
        this.updateBackgroundParallax = this.updateBackgroundParallax.bind(this)
        this.state = {
            position: '0px',

            fireapp: null,
            user: null
        }
    }
    componentDidMount(){
        window.addEventListener('scroll', this.updateBackgroundParallax)

        const fireapp = initializeApp(fireapp_settings)
        const auth = getAuth(fireapp)

        onAuthStateChanged(auth, user => this.setState({ user }))

        this.setState({
            fireapp: { fireapp, auth }
        })
    }
    componentWillUnmount(){
        window.removeEventListener('scroll', this.updateBackgroundParallax)
    }
    updateBackgroundParallax(){
        this.setState({position: `${-window.pageYOffset * 1.1}px`})
    }

    render(){
        return (
            <SharedContext.Consumer>
                { ({data}) => {
                    const canRegister = ['OPEN', 'CLOSED_PRES', 'ONE_LINK'].findIndex(x => data?.registration_state === x) !== -1
                    const thanks = ['THANKS'].findIndex(x => data?.registration_state === x) !== -1
                    return (
                        <div className={`titleScreen ${this.state.user ? 'magic': ''}`} style={{backgroundPositionY: this.state.position}}>
                            <div className="logo" alt="pinoc, btw best girl sayori">
                            </div>
                            <div className="desc">Ostravská přednášková noc</div>
            
                            <div className="buttons">
                                { (canRegister && data?.registration_url) ? <div className="button" onClick={_ => this.props.change('registration')}>Registrace</div> : null}
                                { ((data?.schedule_url.trim() || '') !== '') ? <div className="button" onClick={_ => window.open(data?.schedule_url.trim(), '_blank')}>Harmonogram</div> : null }
                                { ((data?.instruction_url.trim() || '') !== '') ? <div className="button" onClick={_ => window.open(data?.instruction_url.trim(), '_blank')}>Pokyny pro účastníky</div> : null }
                                { (thanks) ? <div className="desc">Těšíme se na Vás na dalším ročníku!</div> : null}
                            </div>
                    </div>
                    )
                } }
            </SharedContext.Consumer>
        )
    }
}