import React, { Component } from 'react'
import './navbar.scss'
import Logo from '../../assets/mini_logo.svg'
import { FaFacebook, FaInstagram, FaGooglePlay, FaDiscord } from 'react-icons/fa'

const NavBarItem = props => {
    return <div className='link' onClick={props.onClick}>{props.children}</div>
}

export default class NavBar extends Component{
    constructor(props){
        super(props)
        this.ensureVisibility = this.ensureVisibility.bind(this)
        this.state = {
            scrolledDown: false,
            menuOpened: false
        }
    }
    componentDidMount(){
        window.addEventListener('scroll', this.ensureVisibility)
        this.ensureVisibility()
    }
    componentWillUnmount(){
        window.removeEventListener('scroll', this.ensureVisibility)
    }
    ensureVisibility(){
        this.setState({
            scrolledDown: window.pageYOffset >= window.innerHeight - 32
        })
    }
    render(){
        return (
            <div className={'navbar' + (this.state.scrolledDown ? ' scrolledDown' : '')}>
                <div className='wrapper'>
                    <img src={Logo} alt="pinoc" onClick={_ => this.props.change("home")}/>
                    <div className="menu">
                        <div className="openBtn" onClick={_ => this.setState({menuOpened: !this.state.menuOpened})}>≡</div>
                        <div className={"links " + (this.state.menuOpened ? "visible " : "")}>
                            {
                                Object.entries(this.props.buttons).map((x,i) =>
                                    x[1].title == null ? null : <NavBarItem
                                        key={`navbar_${i}`}
                                        onClick={_ => {
                                            this.setState({menuOpened: !this.state.menuOpened})
                                            this.props.change(x[0])
                                        }}
                                    >{x[1].title}</NavBarItem>
                                )
                            }
                            <NavBarItem onClick={_=>window.open('https://www.facebook.com/ostravskaprednaskovanoc')}>
                                <FaFacebook className="icon"/>
                            </NavBarItem>
                            <NavBarItem onClick={_=>window.open('https://www.instagram.com/pi_noc/')}>
                                <FaInstagram className="icon"/>
                            </NavBarItem>
                            {/* <NavBarItem onClick={_=>window.open('https://play.google.com/store/apps/details?id=com.jakubmurcek.pinoc')}>
                                <FaGooglePlay className="icon"/>
                            </NavBarItem> */}
                            <NavBarItem onClick={_=>window.open('https://discord.gg/vN4PPg2yk7')}>
                                <FaDiscord className="icon"/>
                            </NavBarItem>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}