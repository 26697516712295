export const lerp = (a,b,t) => a+(b-a)*t

const ending = '$$$'

export const unpackPresentations = (strPresentations) => {
    const results = strPresentations.split(ending)
        .filter(x => x.trim().length !== 0)
        .map(x => {
            const [ mName, mAuthor, mUrl, mType, ...mDesc ] = x.trim().split('\n')
            
            if(!mName || !mAuthor || !mUrl || !mType || !mDesc){
                console.error('Failed to parse presentation', x)
                return null
            }

            const name = mName.split(':').slice(1).join(':').trim()
            const author = mAuthor.split(':').slice(1).join(':').trim()
            const url = mUrl.split(':').slice(1).join(':').trim()
            const type = mType.split(':').slice(1).join(':').trim()
            const desc = mDesc.join('\n').trim()

            if(name.length === 0 || author.length === 0 || url.length === 0 || type.length === 0 || desc.length === 0){
                console.error('Failed to parse presentation', x)
                return null
            }
            return { name, author, image: url, type, description: desc }
        })

    return results
}

export const validatePresString = (strPresentations) => {
    return unpackPresentations(strPresentations).find(x => x === null) !== null
}

const monthsInFall = [
    'bruh', // 0
    'ledna', // 1
    'února',
    'března',
    'dubna',
    'května',
    'června',
    'července',
    'srpna',
    'září',
    'října',
    'listopadu',
    'prosince'
]

// czech date sat on the wall, czech date has a great fall
export const czechDateButInCorrectFall = (date, month, year) => {
    if(date && !month && !year){
        const [ _year, _month, _date ] = date.split('-')
        const [ __year, _time ] = _year.split('T')

        date = parseInt(_date)
        month = parseInt(_month)
        year = parseInt(__year)
    }
    return `${date}. ${monthsInFall[month]}` + (year ? ` ${year}` : '')
}