const imageSources = {
    // reason why we keep it constant because we ain't changing it
    // and because google cloud storage sucks ass with managing it
    // want to move X to Y? download X, delete X, create Y, move to Y
    // it's so counterintuitive so i just uploaded it there for the
    // good quota limits but said "fuck this" and linked it absolutely
    'Archiv 2019': _ => Promise.resolve([
        {thumb:"thumb%2FIMG_2908.JPG?alt=media",image:"IMG_2908.JPG?alt=media"},
        {thumb:"thumb%2FIMG_2927.JPG?alt=media",image:"IMG_2927.JPG?alt=media"},
        {thumb:"thumb%2FIMG_2945.JPG?alt=media",image:"IMG_2945.JPG?alt=media"},
        {thumb:"thumb%2FIMG_2951.JPG?alt=media",image:"IMG_2951.JPG?alt=media"},
        {thumb:"thumb%2FIMG_2968.JPG?alt=media",image:"IMG_2968.JPG?alt=media"},
        {thumb:"thumb%2FIMG_2978.JPG?alt=media",image:"IMG_2978.JPG?alt=media"},
        {thumb:"thumb%2FIMG_3002.JPG?alt=media",image:"IMG_3002.JPG?alt=media"},
        {thumb:"thumb%2FIMG_3014.JPG?alt=media",image:"IMG_3014.JPG?alt=media"},
        {thumb:"thumb%2FIMG_3038.JPG?alt=media",image:"IMG_3038.JPG?alt=media"},
        {thumb:"thumb%2FIMG_3053.JPG?alt=media",image:"IMG_3053.JPG?alt=media"},
        {thumb:"thumb%2FIMG_3068.JPG?alt=media",image:"IMG_3068.JPG?alt=media"},
        {thumb:"thumb%2FIMG_3079.JPG?alt=media",image:"IMG_3079.JPG?alt=media"},
        {thumb:"thumb%2FIMG_3085.JPG?alt=media",image:"IMG_3085.JPG?alt=media"},
        {thumb:"thumb%2FIMG_3099.JPG?alt=media",image:"IMG_3099.JPG?alt=media"},
        {thumb:"thumb%2FIMG_3105.JPG?alt=media",image:"IMG_3105.JPG?alt=media"},
        {thumb:"thumb%2FIMG_3113.JPG?alt=media",image:"IMG_3113.JPG?alt=media"},
        {thumb:"thumb%2FIMG_3121.JPG?alt=media",image:"IMG_3121.JPG?alt=media"},
        {thumb:"thumb%2FIMG_2909.JPG?alt=media",image:"IMG_2909.JPG?alt=media"},
        {thumb:"thumb%2FIMG_2932.JPG?alt=media",image:"IMG_2932.JPG?alt=media"},
        {thumb:"thumb%2FIMG_2947.JPG?alt=media",image:"IMG_2947.JPG?alt=media"},
        {thumb:"thumb%2FIMG_2952.JPG?alt=media",image:"IMG_2952.JPG?alt=media"},
        {thumb:"thumb%2FIMG_2969.JPG?alt=media",image:"IMG_2969.JPG?alt=media"},
        {thumb:"thumb%2FIMG_2981.JPG?alt=media",image:"IMG_2981.JPG?alt=media"},
        {thumb:"thumb%2FIMG_3005.JPG?alt=media",image:"IMG_3005.JPG?alt=media"},
        {thumb:"thumb%2FIMG_3017.JPG?alt=media",image:"IMG_3017.JPG?alt=media"},
        {thumb:"thumb%2FIMG_3039.JPG?alt=media",image:"IMG_3039.JPG?alt=media"},
        {thumb:"thumb%2FIMG_3056.JPG?alt=media",image:"IMG_3056.JPG?alt=media"},
        {thumb:"thumb%2FIMG_3070.JPG?alt=media",image:"IMG_3070.JPG?alt=media"},
        {thumb:"thumb%2FIMG_3080.JPG?alt=media",image:"IMG_3080.JPG?alt=media"},
        {thumb:"thumb%2FIMG_3089.JPG?alt=media",image:"IMG_3089.JPG?alt=media"},
        {thumb:"thumb%2FIMG_3101.JPG?alt=media",image:"IMG_3101.JPG?alt=media"},
        {thumb:"thumb%2FIMG_3106.JPG?alt=media",image:"IMG_3106.JPG?alt=media"},
        {thumb:"thumb%2FIMG_3114.JPG?alt=media",image:"IMG_3114.JPG?alt=media"},
        {thumb:"thumb%2FIMG_3123.JPG?alt=media",image:"IMG_3123.JPG?alt=media"},
        {thumb:"thumb%2FIMG_2916.JPG?alt=media",image:"IMG_2916.JPG?alt=media"},
        {thumb:"thumb%2FIMG_2938.JPG?alt=media",image:"IMG_2938.JPG?alt=media"},
        {thumb:"thumb%2FIMG_2949.JPG?alt=media",image:"IMG_2949.JPG?alt=media"},
        {thumb:"thumb%2FIMG_2956.JPG?alt=media",image:"IMG_2956.JPG?alt=media"},
        {thumb:"thumb%2FIMG_2971.JPG?alt=media",image:"IMG_2971.JPG?alt=media"},
        {thumb:"thumb%2FIMG_2982.JPG?alt=media",image:"IMG_2982.JPG?alt=media"},
        {thumb:"thumb%2FIMG_3007.JPG?alt=media",image:"IMG_3007.JPG?alt=media"},
        {thumb:"thumb%2FIMG_3021.JPG?alt=media",image:"IMG_3021.JPG?alt=media"},
        {thumb:"thumb%2FIMG_3040.JPG?alt=media",image:"IMG_3040.JPG?alt=media"},
        {thumb:"thumb%2FIMG_3060.JPG?alt=media",image:"IMG_3060.JPG?alt=media"},
        {thumb:"thumb%2FIMG_3075.JPG?alt=media",image:"IMG_3075.JPG?alt=media"},
        {thumb:"thumb%2FIMG_3081.JPG?alt=media",image:"IMG_3081.JPG?alt=media"},
        {thumb:"thumb%2FIMG_3090.JPG?alt=media",image:"IMG_3090.JPG?alt=media"},
        {thumb:"thumb%2FIMG_3102.JPG?alt=media",image:"IMG_3102.JPG?alt=media"},
        {thumb:"thumb%2FIMG_3111.JPG?alt=media",image:"IMG_3111.JPG?alt=media"},
        {thumb:"thumb%2FIMG_3115.JPG?alt=media",image:"IMG_3115.JPG?alt=media"},
        {thumb:"thumb%2FIMG_3127.JPG?alt=media",image:"IMG_3127.JPG?alt=media"},
        {thumb:"thumb%2FIMG_2923.JPG?alt=media",image:"IMG_2923.JPG?alt=media"},
        {thumb:"thumb%2FIMG_2939.JPG?alt=media",image:"IMG_2939.JPG?alt=media"},
        {thumb:"thumb%2FIMG_2950.JPG?alt=media",image:"IMG_2950.JPG?alt=media"},
        {thumb:"thumb%2FIMG_2963.JPG?alt=media",image:"IMG_2963.JPG?alt=media"},
        {thumb:"thumb%2FIMG_2972.JPG?alt=media",image:"IMG_2972.JPG?alt=media"},
        {thumb:"thumb%2FIMG_2985.JPG?alt=media",image:"IMG_2985.JPG?alt=media"},
        {thumb:"thumb%2FIMG_3008.JPG?alt=media",image:"IMG_3008.JPG?alt=media"},
        {thumb:"thumb%2FIMG_3026.JPG?alt=media",image:"IMG_3026.JPG?alt=media"},
        {thumb:"thumb%2FIMG_3044.JPG?alt=media",image:"IMG_3044.JPG?alt=media"},
        {thumb:"thumb%2FIMG_3064.JPG?alt=media",image:"IMG_3064.JPG?alt=media"},
        {thumb:"thumb%2FIMG_3077.JPG?alt=media",image:"IMG_3077.JPG?alt=media"},
        {thumb:"thumb%2FIMG_3084.JPG?alt=media",image:"IMG_3084.JPG?alt=media"},
        {thumb:"thumb%2FIMG_3096.JPG?alt=media",image:"IMG_3096.JPG?alt=media"},
        {thumb:"thumb%2FIMG_3104.JPG?alt=media",image:"IMG_3104.JPG?alt=media"},
        {thumb:"thumb%2FIMG_3112.JPG?alt=media",image:"IMG_3112.JPG?alt=media"},
        {thumb:"thumb%2FIMG_3116.JPG?alt=media",image:"IMG_3116.JPG?alt=media"}
    ].map(x => {
        const y = 'https://firebasestorage.googleapis.com/v0/b/pinoc-2019.appspot.com/o/archive_2019%2F'
        x.thumb = y + x.thumb
        x.image = y + x.image
        return x
    })),
    'Archiv 2022 Léto': _ => Promise.resolve([
        'DSC_0361.jpg',
        'DSC_0362.jpg',
        'DSC_0363.jpg',
        'DSC_0364.jpg',
        'DSC_0370.jpg',
        'DSC_0372.jpg',
        'DSC_0376.jpg',
        'DSC_0377.jpg',
        'DSC_0378.jpg',
        'DSC_0380.jpg',
        'DSC_0381.jpg',
        'DSC_0382.jpg',
        'DSC_0383.jpg',
        'DSC_0384.jpg',
        'DSC_0386.jpg',
        'DSC_0388.jpg',
        'DSC_0390.jpg',
        'DSC_0392.jpg',
        'DSC_0395.jpg',
        'DSC_0396.jpg',
        'DSC_0398.jpg',
        'DSC_0400.jpg',
        'DSC_0402.jpg',
        'DSC_0404.jpg',
        'DSC_0405.jpg',
        'DSC_0406.jpg',
        'DSC_0409.jpg',
        'DSC_0418.jpg',
        'DSC_0419.jpg',
        'DSC_0422.jpg',
        'DSC_0429.jpg',
        'DSC_0435.jpg',
        'DSC_0436.jpg',
        'DSC_0439.jpg',
        'DSC_0442.jpg',
        'DSC_0446.jpg',
        'DSC_0447.jpg',
        'DSC_0448.jpg',
        'DSC_0449.jpg',
        'DSC_0454.jpg',
        'DSC_0456.jpg',
        'DSC_0457.jpg',
        'DSC_0459.jpg',
        'DSC_0462.jpg',
        'DSC_0465.jpg',
        'DSC_0471.jpg',
        'DSC_0474.jpg',
        'DSC_0476.jpg',
        'DSC_0478.jpg',
        'IMG_4639.jpg',
        'IMG_4645.jpg',
    ].map(x => {
        return { thumb: 'thumb_' + x, image: x }
    }).map(x => {
        const y = 'https://firebasestorage.googleapis.com/v0/b/pinoc-2019.appspot.com/o/2022_winter%2F'
        x.thumb = y + x.thumb + '?alt=media'
        x.image = y + x.image + '?alt=media'
        return x
    })),
    'Archiv 2022 Zima': _ => Promise.resolve([
        'DSC_0966.jpg',
        'DSC_0934.jpg',
        'DSC_0929.jpg',
        'DSC_0920.jpg',
        'DSC_0866.jpg',
        'DSC_0819.jpg',
        'DSC_0779.jpg',
        'DSC_0548.jpg',
        'DSC_0533.jpg',
        'DSC_0429.jpg',
        'DSC_0425.jpg',
        'DSC_0418.jpg',
        'DSC_0404.jpg',
        'DSC_0398.jpg',
        'DSC_0393.jpg',
        'DSC_0372.jpg',
        'DSC_0364.jpg',
        'DSC_0361.jpg',
        'DSC_0354.jpg',
        'DSC_0350.jpg',
        'DSC03496.jpg',
        'DSC03495.jpg',
        'DSC03492.jpg',
        'DSC03481.jpg',
        'DSC03475.jpg',
        'DSC03465.jpg',
        'DSC03461.jpg',
        'DSC03456.jpg',
        'DSC03445.jpg',
        'DSC03441.jpg',
        'DSC03439.jpg',
        'DSC03437.jpg',
        'DSC03434.jpg',
        'DSC03428.jpg',
        'DSC03414.jpg',
        'DSC03404.jpg',
        'DSC03403.jpg',
        'DSC03401.jpg',
        'DSC03396.jpg',
        'DSC03395.jpg',
        'DSC_0336.jpg',
    ].map(x => {
        return { thumb: 'thumb_' + x, image: x }
    }).map(x => {
        const y = 'https://firebasestorage.googleapis.com/v0/b/pinoc-2019.appspot.com/o/2022_winter_pictures%2F'
        x.thumb = y + x.thumb + '?alt=media'
        x.image = y + x.image + '?alt=media'
        return x
    })),
    'Archiv 2023': _ => Promise.resolve([
        'IMG_1356.jpg',
        'IMG_1358.jpg',
        'IMG_1364.jpg',
        'IMG_1377.jpg',
        'IMG_1379.jpg',
        'IMG_1381.jpg',
        'IMG_1382.jpg',
        'IMG_1393.jpg',
        'IMG_1406.jpg',
        'IMG_1410.jpg',
        'IMG_1419.jpg',
        'IMG_1422.jpg',
        'IMG_1428.jpg',
        'IMG_1434.jpg',
        'IMG_1435.jpg',
        'IMG_1437.jpg',
        'IMG_1443.jpg',
        'IMG_1478.jpg',
        'IMG_1481.jpg',
        'IMG_1487.jpg',
        'IMG_1495.jpg',
        'IMG_1511.jpg',
        'IMG_1516b.jpg',
        'IMG_1522.jpg',
        'IMG_1535.jpg',
        'IMG_1545.jpg',
        'IMG_1546.jpg',
        'IMG_1552.jpg',
        'IMG_1576.jpg',
        'IMG_1624.jpg',
        'IMG_1625.jpg',
    ].map(x => {
        return { thumb: 'thumb_' + x, image: x }
    }).map(x => {
        const y = 'https://firebasestorage.googleapis.com/v0/b/pinoc-2019.appspot.com/o/pictures%2F2023%2F'
        x.thumb = y + x.thumb + '?alt=media'
        x.image = y + x.image + '?alt=media'
        return x
    }))
    /*,
    'HoloLive Safebooru': _ => safebooru('hololive')*/
}

export default imageSources