import React, { Component } from 'react'
import './navbar.scss'
import Logo from '../../assets/mini_logo.svg'
import { FaFacebook, FaInstagram, FaDiscord } from 'react-icons/fa'

const NavBarItem = props => {
    return <div className='link' onClick={props.onClick}>{props.children}</div>
}

export default class NavBarOrg extends Component{
    constructor(props){
        super(props)
        this.state = {
            menuOpened: false
        }
    }
    render(){
        return (
            <div className={'navbar scrolledDown'}>
                <div className='wrapper'>
                    <img src={Logo} alt="pinoc" onClick={_ => window.location.replace(".")}/>
                    <div className="menu">
                        <div className="openBtn" onClick={_ => this.setState({menuOpened: !this.state.menuOpened})}>≡</div>
                        <div className={"links " + (this.state.menuOpened ? "visible " : "")}>
                            <NavBarItem onClick={_=>window.open('https://www.facebook.com/ostravskaprednaskovanoc')}>
                                <FaFacebook className="icon"/>
                            </NavBarItem>
                            <NavBarItem onClick={_=>window.open('https://www.instagram.com/pi_noc/')}>
                                <FaInstagram className="icon"/>
                            </NavBarItem>
                            <NavBarItem onClick={_=>window.open('https://discord.gg/vN4PPg2yk7')}>
                                <FaDiscord className="icon"/>
                            </NavBarItem>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}