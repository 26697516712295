import { signInWithEmailAndPassword, signOut } from "firebase/auth"

const getPresentations = () => null

const gFormsEndpoint = 'https://docs.google.com/forms/d/e/1FAIpQLSfNMlwYsETwWPURHNaTX0O4lotkH2MzxvYdFV4fRqPASivT-g/formResponse'
const gFormsMap = {
    fname:    'entry.448988756',
    lname:    'entry.1523653613',
    bday:     'entry.1276412855', // YYYY-mm-dd
    phonenum: 'entry.1778389707',
    email:    'entry.959168230',
    // consent:  'entry.0006',

    presentation_0_title:   'entry.225613978',
    presentation_0_desc:   'entry.1831284900',
    presentation_0_length: 'entry.989390749',

    presentation_1_title:   'entry.1375330229',
    presentation_1_desc:   'entry.728851134',
    presentation_1_length: 'entry.804725810'
}
const sendRegistration = async (data) => {
    // TODO: remap the data
    const dataObject = Object.fromEntries(['fname', 'lname', 'bday', 'phonenum', 'email'].map(key => {
        return [ gFormsMap[key], data[key] ]
    }))

    let i = 0
    for(const pres of (data.presentation || [])){
        const id = i++
        dataObject[gFormsMap[`presentation_${id}_title`]] = pres.title
        dataObject[gFormsMap[`presentation_${id}_desc`]] = pres.description
        dataObject[gFormsMap[`presentation_${id}_length`]] = pres.length
    }

    const formData = new URLSearchParams(dataObject).toString()

    const response = await fetch(gFormsEndpoint, {
        method: 'post',
        body: formData,
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        mode: 'no-cors'
    })
    console.log(response, response.ok, response.status, response.statusText)
    if(response.ok) return true
    else throw Error(response.statusText)
}

const PUBLIC = { getPresentations, sendRegistration }
export default PUBLIC















// --------------------------------------------------------------------------------------------------------- //
const mock_db = {
    inaa: {
        forename: 'Ina\'nis',
        surname: 'Ninomae',
        birthday: '2019-05-20',
        phonenum: '000000000',
        email: 'inainainaa@tentacu.lt',
        consent: true,
        paid: true,
        presentation: {
            slot0: {
                approved: true,
                description: 'But still tax deductable!',
                length: 1,
                room: '206',
                time: '10:30',
                title: 'We are totally not a cult.'
            }
        }
    },
    waatson: {
        forename: 'Watson',
        surname: 'Amelia',
        birthday: '2019-05-20',
        phonenum: '000000000',
        email: 'amelia@watson.gov',
        consent: false,
        paid: true,
        presentation: {
            slot0: {
                approved: false,
                description: 'Just kidding, I am here for different reasons though.',
                length: 1,
                room: '206',
                time: '10:30',
                title: 'I got dragged here against my will. Send help.'
            }
        }
    },
    gaawr: {
        forename: 'Gawr',
        surname: 'Gura',
        birthday: '2019-05-20',
        phonenum: '000000000',
        email: 'ggggggggggggggg@atlantis.sk',
        consent: true,
        paid: false,
        presentation: null
    }
}

const mock_global_data = {
    registration_state: 'CLOSED'
}

/**
 * @param {Auth} auth
 * @param {string} username
 * @param {string} password
 */
const login = (auth, username, password) => signInWithEmailAndPassword(auth, username, password)
/**
 * @param {Auth} auth
 */
const logout = (auth) => signOut(auth)
const getPeople = (id = null) => (new Promise((s,r) => setTimeout(_ => s(id ? mock_db[id] : mock_db), 1000)));
const setPersonData = (id, data) => (new Promise((s,r) => setTimeout(_ => r('fuck you vig'), 1000)));
const setGlobalData = (data) => (new Promise((s,r) => setTimeout(_ => s('fuck you vig'), 1000)));
const getGlobalData = () => (new Promise((s,r) => setTimeout(_ => s(mock_global_data), 10)));

export const ADMIN = { login, logout, getPeople, setPersonData, setGlobalData, getGlobalData }