import React, { Component } from 'react';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue } from 'firebase/database';
import { fireapp_settings } from '../constants';
import styles from './EclipseStyles/Harmonogram.module.scss';

// Initialize Firebase
const app = initializeApp(fireapp_settings);
const database = getDatabase(app);

// Define fixup object
const fixup = {
    'STUDENT': 'pres',
    'event': 'event',
    'MAIN': 'mainpres'
};

class Harmonogram extends Component {
  state = {
    presentations: [],
    rooms: {}
  };

  componentDidMount() {
    this.fetchRooms();
    this.fetchPresentations();
  }

  fetchRooms() {
    const roomsRef = ref(database, 'rooms');
    onValue(roomsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        this.setState({ rooms: data });
      } else {
        console.error('No rooms data found.');
      }
    }, (error) => {
      console.error('Error fetching rooms data:', error);
    });
  }
  
  fetchPresentations() {
    const presentationsRef = ref(database, 'presentations');
    onValue(presentationsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const presentations = Object.values(data).filter(p => !p.hidden);
        this.setState({ presentations });
      } else {
        console.error('No presentations data found.');
      }
    }, (error) => {
      console.error('Error fetching presentations data:', error);
    });
  }

  render() {
    const { presentations, rooms } = this.state;

    // Group presentations by room
    const presentationsByRoom = presentations.reduce((acc, event) => {
      if (typeof(event) === 'object' && event.time && !event.disable) {
        if (!acc[event.room]) acc[event.room] = [];
        acc[event.room].push(event);
      }
      return acc;
    }, {});

    return (
      <div className={styles.harmonogram}>
        <div className={styles.timeline}>
          <div>17:00</div><div>17:30</div><div>18:00</div><div>18:30</div><div>19:00</div>
          <div>19:30</div><div>20:00</div><div>20:30</div><div>21:00</div><div>21:30</div>
          <div>22:00</div><div>22:30</div><div>23:00</div><div>23:30</div><div>00:00</div>
          <div>00:30</div><div>01:00</div><div>01:30</div><div>02:00</div><div>02:30</div>
          <div>03:00</div><div>03:30</div>
        </div>
        <div className={styles.timelineBackdrop}></div>
        {Object.keys(presentationsByRoom).map(roomName => (
          <div className={styles.row} key={roomName}>
            <div className={styles.room}>
              <div className={styles['room-text']}>{rooms[roomName] || roomName}</div>
            </div>
            <div className={styles.presentations}>
              {presentationsByRoom[roomName].map(event => (
                <div 
                  className={`${styles.presentation} ${styles[fixup[event.type] || 'default']}`}
                  key={event.id}
                  style={{
                    left: `calc(var(--hour-width) * ${this.calculateLeftOffset(event.time)})`,
                    width: `calc(var(--hour-width) * ${this.calculateWidth(event.time, event.length)})`
                  }}
                >
                  <div className={styles.icon}>
                    <div className={styles.overlay}>
                      <div className={styles.text}>
                        {event.time}–{this.formatEndTime(event.time, event.length)}
                      </div>
                    </div>
                  </div>
                  <div className={styles.metadata}>
                    <div className={styles.subtitle}>
                      {event.time}–{this.formatEndTime(event.time, event.length)}
                    </div>
                    <div className={styles.title}>{event.name}</div>
                    <div className={styles.subtitle}>{event.author}</div>
                  </div>
                  <div className={styles.follow}>
                    <ion-icon name="star-outline"></ion-icon>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  }

  calculateLeftOffset(time) {
    const [hour, minute] = time.split(':').map(x => parseInt(x, 10));
    const minutes = (hour < 17 ? 24 + hour : hour) * 60 + minute - (17 * 60);
    return minutes / 60;
  }

  calculateWidth(startTime, length) {
    const startMinutes = this.timeToMinutes(startTime);
    const endMinutes = startMinutes + parseInt(length, 10);
    const duration = endMinutes - startMinutes;
    return duration / 60;
  }

  formatEndTime(startTime, length) {
    const startMinutes = this.timeToMinutes(startTime);
    const endMinutes = startMinutes + parseInt(length, 10);
    return this.minutesToTime(endMinutes);
  }

  timeToMinutes(time) {
    const [hour, minute] = time.split(':').map(x => parseInt(x, 10));
    return hour * 60 + minute;
  }

  minutesToTime(minutes) {
    let hour = Math.floor(minutes / 60);
    const minute = minutes % 60;

    // If hour exceeds 24, reset it to show proper time after midnight
    if (hour >= 24) {
        hour = hour - 24;
    }

    return `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
  }
}

export default Harmonogram;
