import React from 'react'
import './main.scss'
import Markdown from 'react-markdown'

function TextScreen(props){
    return (
        <div className={"textScreen " + (props.className || '')} style={{backgroundColor: props.background || 'inherit', ...props.style}} ref={props.fRef}>
            {
                props.source ? createMarkdown(props.source) :
                React.Children.map(props.children, x => {
                    if(typeof(x) === 'string') return createMarkdown(x)
                    else return x
                })
            }
        </div>
    )
}
function createMarkdown(source){
    return (<Markdown className="content" children={source}/>)
}

export default React.forwardRef((props,ref) => 
    <TextScreen {...props} fRef={ref}>{props.children}</TextScreen>
)