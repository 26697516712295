import { React, useState, useEffect, createContext, useContext, useReducer } from 'react'
import { Routes, Route, Link, useNavigate, useParams } from 'react-router-dom'

import { VechaiProvider, Button, Spinner, FormControl, Input, FormLabel, Checkbox, Select } from '@vechaiui/react'

import { GiConfirmed } from 'react-icons/gi'
import { BsCurrencyDollar, BsPencilSquare, BsSearch } from 'react-icons/bs'
import { IoIosArrowBack, IoIosLogOut, IoIosSettings, IoMdRefresh } from 'react-icons/io'

import { initializeApp } from 'firebase/app'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { getStorage, ref, listAll, uploadBytes, deleteObject } from 'firebase/storage'
import { getDatabase, get, child, ref as dbRef, set } from 'firebase/database'
import { fireapp_settings } from '../constants'

import { ADMIN as api } from '../lib/api'
import { validatePresString } from '../lib/util'
import { PresentationCard } from '../lib/components/PresentationGroup'
import { OrganizerCard } from '../lib/components/OrganizerGroup'

/**************************************************************************************************/

const BackButton = (props) => {
    return (
        <Link to='/landlord/people' className='!text-black w-8 h-8 inline-flex text-2xl justify-center hover:!text-gray-500 transition-colors items-center mr-4'>
            <IoIosArrowBack/>
        </Link>
    )
}

const ErrorNotification = (props) => {
    return <div className={`bg-red-600 w-full px-4 py-2 rounded-lg text-white ${props.className}`}>{props.children}</div>
}

const FirebaseContext = createContext(null)
const UserContext = createContext(null)

const stateReducer = (state, action) => ({
    ...state,
    ...(typeof action === 'function' ? action(state) : action),
})

/**************************************************************************************************/

const Login = (props) => {
    const navigate = useNavigate()
    const { auth } = useContext(FirebaseContext)
    const user = useContext(UserContext)

    const [ loading, setLoading ] = useState(false)
    const [ error, setError ] = useState(false)

    const [ username, setUsername ] = useState('')
    const [ password, setPassword ] = useState('')

    useEffect(() => {
        if(user) navigate('/landlord/control')
    }, [navigate, user])

    const Login = async (e) => {
        e.preventDefault();
        
        setError(null)
        setLoading(true)

        try{
            let user = await api.login(auth, username, password)
            console.log(user) // successful login, changes handled on the top
        }catch(err){
            console.error(err)
            setError(err.toString())
        }finally{
            setLoading(false)
        }
    }

    return (
        <div className='bg-white rounded-lg'>
            <div className='flex flex-wrap container space-x-4 p-4 flex-col'>
                <h1 className='text-4xl pb-8'>landlord.vigne</h1>
                <form className='space-y-4 !ml-0'>
                    <FormControl id='email'>
                        <Input placeholder='cock@and.balls' value={username} onChange={e => setUsername(e.target.value)}/>
                    </FormControl>
                    <FormControl id='password'>
                        <Input type='password' value={password} placeholder='bigdickme' onChange={e => setPassword(e.target.value)}/>
                    </FormControl>

                    <div className='flex flex-col'>
                        <Button type='submit' variant='solid' color='primary' loading={loading} onClick={Login}>Attempt to get into my butthole</Button>
                        {error ? <ErrorNotification className='mt-2 text-sm'>{error}</ErrorNotification> : null}
                    </div>
               </form>
            </div>
        </div>
    )
}

const SimpleButton = (props) => {
    const color = props.color || 'gray'
    return (
        <div onClick={props.onClick} className={`select-none flex transition-colors justify-center items-center text-zinc-50 px-2 rounded-lg cursor-pointer bg-${color}-500 hover:bg-${color}-400 active:bg-${color}-600 ${props.className}`}>{props.children}</div>
    )
}

/**************************************************************************************************/
//
//  What you are witnessing are classes, meant for a very simple user management, however due to
//  being cheapskates, we weren't able to use some fancy APIs and not wanting to cut back here,
//  it was just commented out, left forever to... NEVER be used.
//
//  Kod byl upraven a castecne funguje, sam celkem koukam. Tento system vsak nahradila externi sluzba a tento
//  kod zustal v zapomenuti do te doby, nez se ho nejaky odvazny clovek odvazi zfunkcnit. Moznosti
//  je mnoho, penez mnoho neni. Jak pravil buh vignedev - due to being cheapskates... NEVER BE USED.

/*
const Tag = (props) => {
    const { icon, enabled } = props
    return (
        <div className={`flex justify-center items-center text-zinc-50 ${props.className} ${(enabled === null) ? ('bg-gray-300') : (enabled ? 'bg-green-600' : 'bg-red-600')} p-2 rounded-lg`}>{icon}</div>
    )
}

const PersonRow = (props) => {
    const { data } = props
    return (
        <Link to={data.id} className='!text-black cursor-pointer p-2 px-4 shadow-lg mx-auto rounded-lg bg-white container hover:bg-violet-50 active:bg-violet-400 active:!text-zinc-50 transition-colors select-none flex flex-row justify-between items-center'>
            <span className='font-bold'>{data.surname} {data.forename}</span>

            <div className='flex h-full space-x-2'>
                <Tag icon={<GiConfirmed/>} enabled={data.consent}/>
                <Tag icon={<BsCurrencyDollar/>} enabled={data.paid}/>
                <Tag icon={<BsPencilSquare/>} enabled={data.presentation && Object.values(data.presentation).every(x => x.approved)}/>
            </div>
        </Link>
    )
}

const FilterButton = (props) => {
    const { value, setValue } = props

    const colors = {
        null: 'bg-gray-500 hover:bg-gray-400 active:bg-gray-600',
        true: 'bg-green-500 hover:bg-green-400 active:bg-green-600',
        false: 'bg-red-500 hover:bg-red-400 active:bg-red-600'
    }

    return (
        <div onClick={e => {
            e.preventDefault()
            if(value === null) return setValue(true)
            if(value === true) return setValue(false)
            if(value === false) return setValue(null)
        }} className={`select-none flex transition-colors justify-center items-center text-zinc-50 rounded-lg w-8 px-2 cursor-pointer ${colors[value]}`}>{props.icon}</div>
    )
}
*/

/*
const PeopleList = (props) => {
    const navigate = useNavigate();

    const [ data, setData ] = useState(null);
    const [ error, setError ] = useState(null);

    const { auth } = useContext(FirebaseContext);
    const user = useContext(UserContext);

    const [ search, setSearch ] = useState('');
    const [ paidFilter, setPaidFilter ] = useState(null);
    const [ consentFilter, setConsentFilter ] = useState(null);
    const [ presentationFilter, setPresentationFilter ] = useState(null);

    const getData = async () => {
        setData(null);
        try {
            const result = await api.getPeople();
            setData(result);
        } catch (err) {
            setError(err.toString());
        }
    };

    useEffect(() => {
        if (user === null) navigate('/landlord');
    }, [user, navigate]);

    useEffect(() => {
        getData();
    }, []);

    const filter = (data_in) => {
        return data_in.filter(x => {
            if(paidFilter === true && !x.paid) return false;
            if(paidFilter === false && x.paid) return false;
            if(consentFilter === true && !x.consent) return false;
            if(consentFilter === false && x.consent) return false;
            if(presentationFilter === true && x.presentation === null) return false;
            if(presentationFilter === false && x.presentation !== null) return false;

            const trimmed = search.trim().toLowerCase();
            if (trimmed.length !== 0)
                if(![`${x.forename} ${x.surname}`, `${x.surname} ${x.forename}`].map(x => x.toLowerCase()).some(y => y.includes(trimmed))) return false;

            return true;
        });
    };

    const filtered = data && filter(Object.entries(data).map(([key,val]) => {val.id = key; return val;}));

    return (
        <div className='bg-zinc-100 rounded-lg container mx-auto'>
            <div className='flex flex-wrap container p-4 flex-col'>
                <div className='flex justify-between pb-8 items-center'>
                    <h1 className='text-4xl'>attendees</h1>
                    <div className='flex flex-row items-center space-x-2'>
                        <span className='italic text-sm'>{user ? user.uid : 'Not logged in, what the fuck?'}</span>
                        <SimpleButton className='w-8 h-8' children={<IoMdRefresh/>} onClick={e => getData()}/>
                        <SimpleButton className='w-8 h-8' children={<IoIosSettings/>} onClick={e => navigate('/landlord/control')}/>
                        <SimpleButton className='w-8 h-8' children={<IoIosLogOut/>} onClick={e => api.logout(auth)}/>
                    </div>
                </div>

                <div className='flex flex-row pb-4 space-x-1'>
                    <Input.Group className='grow'>
                        <Input.LeftElement children={<BsSearch/>} className='pointer-events-none text-neutral-300'/>
                        <Input placeholder="Search for the attendees" value={search} onChange={e => setSearch(e.target.value)}/>
                        <Input.RightElement children={data ? filtered.length : 0} className='pointer-events-none text-neutral-300'/>
                    </Input.Group>
                    <FilterButton value={consentFilter}      setValue={setConsentFilter}      icon={<GiConfirmed/>}     />
                    <FilterButton value={paidFilter}         setValue={setPaidFilter}         icon={<BsCurrencyDollar/>}/>
                    <FilterButton value={presentationFilter} setValue={setPresentationFilter} icon={<BsPencilSquare/>}  />
                </div>


                <div className='space-y-2 flex justify-center items-center flex-col'>
                    { (data && !error) ? filtered.map((x,i) => <PersonRow key={`person_${i}`} data={x}/>) : 
                        (!error) ? <Spinner size='xl'/> : <ErrorNotification>failed to obtain data: {error}</ErrorNotification>}
                </div>
            </div>
        </div>
    );
};
*/

/**************************************************************************************************/

/*
const TableRow = (props) => {
    return (<tr>
        <th className='text-left max-w-fit pr-2'>{props.title}</th>
        <td>{props.children}</td>
    </tr>)
}

const PersonEditor = (props) => {
    const user = useContext(UserContext)
    const navigate = useNavigate()
    const { id } = useParams()

    const [ error, setError ] = useState(null)
    const [ data, setData ] = useReducer(stateReducer, null)

    const getData = async() => {
        setData(null)
        try{ setData(await api.getPeople(id)) }
        catch(err){ setError(error != null ? error.toString() : 'null') }
    }

    useEffect(() => {if(user === null) navigate('/landlord')})
    useEffect(() => {getData()})

    return (
        <div className='bg-zinc-100 rounded-lg container mx-auto'>
            <div className='flex flex-wrap container p-4 flex-col'>
                <h1 className='text-4xl pb-8'><BackButton/>person</h1>
                
                {
                    (data && data.surname) ? (
                        <div className='p-4 grid grid-cols-2 gap-4'>
                            <div className='leftSide'>
                                <table className='table-fixed'>
                                    <tbody>
                                        <TableRow title='ID' children={<span className='font-mono'>{id}</span>}/>
                                        <TableRow title='Name' children={data.forename}/>
                                        <TableRow title='Surname' children={data.surname}/>
                                        
                                        <TableRow title='Birthday' children={data.birthday}/>
                                        <TableRow title='Phone Number' children={data.phonenum}/>
                                        <TableRow title='Email' children={<span className='font-mono'>{data.email}</span>}/>

                                    </tbody>
                                </table>

                                <Checkbox.Group className='space-x-4' inline defaultValue={[data.paid ? 'paid' : '', data.consent ? 'consent' : '']} onChange={checked => {
                                    setData({
                                        paid: checked.includes('paid'),
                                        consent: checked.includes('consent')
                                    })
                                }}>
                                    <Checkbox value='paid'>Paid</Checkbox>
                                    <Checkbox value='consent'>Consent</Checkbox>
                                </Checkbox.Group>
                            </div>
                            <div className='rightSide overflow-auto rounded-md text-sm bg-gray-800 text-zinc-50 p-2'>
                                <pre>
                                    {JSON.stringify(data, null, 4)}
                                </pre>
                            </div>
                        </div>
                    ) : <div className='space-y-2 flex justify-center items-center flex-col'>
                        <Spinner size='xl'/>
                    </div>
                }


                {error ? <ErrorNotification className='mt-2 text-sm'>{error}</ErrorNotification> : null}
            </div>
        </div>
    )
}
*/

/**************************************************************************************************/

const FileItem = props => {
    return (
        <><a href={props.href} target='_blank' rel='noreferrer' className={`bg-zinc-600 !py-1 !font-mono text-xs px-2 border-primary-500 hover:border-l-2 hover:bg-zinc-500 text-primary-100 cursor-pointer`} style={{ color: props.new ? 'lime' : '' }}>
            {props.name || props.href}
        </a>
        <Button className='cursor-pointer mb-1 p-1 h-6 mr-2' variant='solid' color='warning' onClick={e =>{
            const storage = getStorage();
            const selectedFile = ref(storage, `2024/${props.name}`);
            deleteObject(selectedFile).then(() => {window.location.reload(false);}).catch((error) => {});
            }}>
                <label className='!mt-0 cursor-pointer'>Smazat {props.name}</label>
        </Button></>
    )
}
const FileBrowser = ({ depends }) => {
    // jesus christ this is uglier than me, lazy ass
    const { setUploading, storage, setFiles, files, loading, isUploading } = depends
    const [ show, setShow ] = useState(false)

    return (
        <div className='control_subsection'>
            <FormLabel>
                <Button className='cursor-pointer mb-1 p-1 h-6 mr-2' variant='solid' color='warning' onClick={e => setShow(!show)}>
                    <label className='!mt-0 cursor-pointer'>{ show ? 'Hide' : 'Show' }</label>
                </Button>
                File Browser
                { show ? <Button className='cursor-pointer mb-1 p-1 h-6 ml-2' variant='solid' loading={loading || isUploading} color='primary'>
                    <label className='!mt-0 cursor-pointer' htmlFor='upload_btn'>Upload</label>
                </Button> : null }
            </FormLabel>

            <input type='file' onChange={e => {
                if(e.target.files.length === 0) return

                setUploading(true)

                uploadBytes(ref(storage, `2024/${e.target.files[0].name}`), e.target.files[0]).then(snapshot => {
                    setFiles([...files, {
                        href: `https://firebasestorage.googleapis.com/v0/b/${snapshot.ref.bucket}/o/${encodeURIComponent(snapshot.ref.fullPath)}?alt=media`,
                        name: snapshot.ref.name,
                        new: true
                    }])
                }).catch((error) => console.error(error)).finally(() => {
                    setUploading(false)
                })
            }} accept='image/*,video/*,.pdf' style={{ display: 'none' }} id='upload_btn' />

            { show ? ( (files) ? <div style={{colorScheme: 'dark'}} className='flex flex-col h-60 max-h-60 overflow-y-scroll rounded-lg bg-zinc-800'>
                <div>sorry, neslo udelat hezceji (vignedev by me ukuchal k smrti)</div>
                { files.map((props, i) => (<FileItem key={`keyItem_${i}_yeehaw`} {...props}/>)) }
            </div> : <Spinner/> ) : null}
        </div>
    )
}

/**************************************************************************************************/

const RegistrationStateSelect = ({ depends }) => {
    const { data, setData } = depends
    return (
        <div className='control_subsection'>
            <FormLabel>Registration State</FormLabel>
            <Select value={data.registration_state} onChange={e => 
                setData({...data, registration_state: e.target.value})
            }>
                <option value='OPEN'>Open</option>
                <option value='CLOSED'>Closed</option>
                <option value='CLOSED_PRES'>Closed (can't register presentations)</option>
                <option value='CLOSED_EARLY'>Closed (too early)</option>
                <option value='CANCELLED'>Cancelled due to various reasons</option>
                <option value='COVID'>Cancelled due to COVID</option>
                <option value='ONE_LINK'>Open on one link</option>
                <option value='THANKS'>Thank for attending</option>
            </Select>
        </div>
    )
}

const SaveButton = ({ depends }) => {
    const { loading, setLoading, setError, database, data, presentations, organizers, rooms } = depends
    return (
        <Button className='cursor-pointer' variant='solid' loading={loading} color='primary' onClick={async () => {
            setLoading(true)
            setError(null)

            try{
                await set(child(dbRef(database), 'global'), data)
                await set(child(dbRef(database), 'presentations'), presentations)
                await set(child(dbRef(database), 'organizers'), organizers)
                await set(child(dbRef(database), 'rooms'), rooms)
            }
            catch(error){ setError(error) }
            finally { setLoading(false) }
        }}>Save settings</Button>
    )
}

const DateSetSection = ({depends}) => {
    const { data, setData } = depends
    const setDataFromTarget = (e, field) => setData({ ...data, [field]: e.target.value })
    return (
        <div className='control_subsection'>
            <FormLabel>Dates</FormLabel>
            { /* forgot what the tailscale classes are and i am not looking them up */ }
            <table style={{width: '100%'}}>
                <tr>
                    <th>Time of venue</th>
                    <th>Deadline for regular people</th>
                    <th>Deadline for speakers</th>
                    <th>Year of venue</th>
                </tr>
                <tr>
                    <td><input value={data.date_venue}   onChange={ e => setDataFromTarget(e, 'date_venue')}   style={{width: '100%'}} type='datetime-local'></input></td>
                    <td><input value={data.date_normal}  onChange={ e => setDataFromTarget(e, 'date_normal')}  style={{width: '100%'}} type='date'></input></td>
                    <td><input value={data.date_speaker} onChange={ e => setDataFromTarget(e, 'date_speaker')} style={{width: '100%'}} type='date'></input></td>
                    <td><input value={data.year} onChange={ e => setDataFromTarget(e, 'year')} style={{width: '100%'}} type='number'></input></td>
                </tr>
            </table>
        </div>
    )
}
const PriceSetSection = ({depends}) => {
    const { data, setData } = depends
    const setDataFromTarget = (e, field) => setData({ ...data, [field]: e.target.value })
    return (
        <div className='control_subsection'>
            <FormLabel>Prices</FormLabel>
            <table style={{width: '100%'}}>
                <tr>
                    <th>Normal price</th>
                    <th>Late price</th>
                </tr>
                <tr>
                    <td><input value={data.price_normal}   onChange={ e => setDataFromTarget(e, 'price_normal')}   style={{width: '100%'}} type='number'></input></td>
                    <td><input value={data.price_late}  onChange={ e => setDataFromTarget(e, 'price_late')}  style={{width: '100%'}} type='number'></input></td>
                </tr>
            </table>
        </div>
    )
}

const SimpleInputField = ({ depends, field, name, haveOpenBtn }) => {
    const { data, setData } = depends
    const setDataFromTarget = (e, field) => setData({ ...data, [field]: e.target.value })

    return (
        <div className='flex items-center'>
            <FormLabel className='mr-2 m-0'>{name || field}</FormLabel>
            <Input className='w-0 grow' value={data[field]} size='xs' onChange={(e => setDataFromTarget(e, field))}/>
            { haveOpenBtn ? <Button size='xs' onClick={ e => window.open(data[field]) }>Open</Button> : null}
        </div>
    )
}

/**************************************************************************************************/

const InputWithLabel = (props) => {
    const { name, field, value, onValueChange, type, options = [] } = props

    return (
        <div className='flex items-center'>
            <FormLabel className='mr-2 m-0'>{name || field}</FormLabel>
            { type === 'multiline' ? 
                (<textarea type={type} className={`text-sm p-0 m-0 w-0 grow ${type === 'checkbox' && 'grow-0'}`} value={value} size='xs' onChange={(e => onValueChange ? onValueChange(e.target.value) : null)}/>) : 
                type === 'dropdown' ? 
                (<select className={`w-0 grow ${type === 'checkbox' && 'grow-0'}`} value={value} onChange={(e => onValueChange ? onValueChange(e.target.value) : null)}>
                    {options.length ? options.map((option, index) => (
                        <option key={index} value={option.value}>{option.label}</option>
                    )) : <option value="">No options available</option>}
                </select>) :
                (<Input type={type} className={`w-0 grow ${type === 'checkbox' && 'grow-0'}`} checked={value} value={value} size='xs' onChange={(e => onValueChange ? onValueChange(type === 'checkbox' ? e.target.checked : e.target.value) : null)}/>)
            }
        </div>
    )
}


const PresentationItem = (props) => {
    const { title, author, onClick } = props
    return (<div className='m-1 cursor-pointer text-sm p-2 item bg-slate-200 overflow-hidden rounded-md' onClick={onClick}>
        <b>{title}</b> by <i>{author}</i>
    </div>)
}

// copied from stackoverflow like a twat https://stackoverflow.com/questions/105034/how-do-i-create-a-guid-uuid
const generateUUID = () => {
    let
      d = new Date().getTime(),
      d2 = (performance && performance.now && (performance.now() * 1000)) || 0;
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
      let r = Math.random() * 16;
      if (d > 0) {
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c == 'x' ? r : (r & 0x7 | 0x8)).toString(16);
    });
  };

const PresentationEditor = (props) => {
    const { data, setData, depends } = props
    const { rooms = {} } = depends;

    const [ show, setShow ] = useState(false)
    const [ index, setIndex ] = useState(null)

    const item = (data && index) ? (data[index] || null) : null

    const addNewPresentation = () => {
        const uuid = generateUUID()
        setData({ ...data, [uuid]: {
            name: '',
            author: '',
            image: '',
            description: '',
            type: 'STUDENT',
            room: '',
            time: '',
            length: ''
        }})
    }
    const deleteAllPresentations = () => {
        Object.keys(data).filter(x => x != 'wah').map(slug => {
            delete data[slug]
            setData(data)
            setIndex(null)
    })}
    return (
        <div className='control_subsection'>
            <FormLabel>
                <Button className='cursor-pointer mb-1 p-1 ph-2 h-6 mr-2' variant='solid' color='warning' onClick={e => setShow(!show)}>
                    <label className='!mt-0 cursor-pointer'>{ show ? 'Hide' : 'Show' }</label>
                </Button>
                Presentations
                { 
                    show ? <Button className='cursor-pointer mb-1 p-1 h-6 ml-2' variant='solid' loading={!!!data} color='primary' onClick={e => addNewPresentation()}>
                        <label className='!mt-0 cursor-pointer'>New</label>
                    </Button> : null
                }
                { 
                    show ? <Button className='cursor-pointer mb-1 p-1 h-6 ml-2' variant='solid' loading={!!!data} color='warning' onClick={e => deleteAllPresentations()}>
                        <label className='!mt-0 cursor-pointer'>Delete All Presentations</label>
                    </Button> : null
                }
            </FormLabel>
            { show ? <div className='flex flex-wrap rounded-lg overflow-hidden'>
                <div className='presentationList min-w-[20em] max-h-[30em] grow bg-slate-50 overflow-auto p-1'>
                    { !data ? <Spinner/> : <>
                        {Object.keys(data).filter(x => x != 'wah').map(slug => 
                            <PresentationItem key={`${slug}_presitem`} title={data[slug]?.name} author={data[slug]?.author} onClick={e => setIndex(slug)}/>
                        )}
                    </> }
                </div>
                <div className='presentationEditor min-w-[20em] grow bg-slate-400 p-3' style={{opacity: item ? 1.0 : 0.5}}>
                    <i>slug: <code>{index}</code></i>
                    <InputWithLabel onValueChange={val => {
                        if(data && index) setData({ ...data, [index]: {...data[index], name: val } })
                    }} value={item?.name || ''} name='Title'/>
                    <InputWithLabel onValueChange={val => {
                        if(data && index) setData({ ...data, [index]: {...data[index], author: val } })
                    }} value={item?.author || ''} name='Author'/>
                    <InputWithLabel onValueChange={val => {
                        if(data && index) setData({ ...data, [index]: {...data[index], image: val } })
                    }} value={item?.image || ''} name='Image'/>
                    <InputWithLabel onValueChange={val => {
                        if(data && index) setData({ ...data, [index]: {...data[index], description: val } })
                    }} type='multiline' value={item?.description || ''} name='Description'/>
                    <InputWithLabel onValueChange={val => {
                        if(data && index) setData({ ...data, [index]: {...data[index], time: val } })
                    }} value={item?.time || ''} name='Start Time'/>
                    <InputWithLabel onValueChange={val => {
                        if (data && index) setData({ ...data, [index]: { ...data[index], room: val } });
                    }} type='dropdown' value={item?.room || ''} name='Room' options={Object.entries(rooms).map(([id, name]) => ({ value: id, label: name }))} />
                    <InputWithLabel onValueChange={val => {
                        if(data && index) setData({ ...data, [index]: {...data[index], length: val } })
                    }} type='number' value={item?.length || ''} name='Length in minutes'/>
                    <InputWithLabel onValueChange={val => {
                        if(data && index) setData({ ...data, [index]: {...data[index], type: val ? 'STUDENT' : 'MAIN' } })
                    }} type='checkbox' value={item?.type === 'STUDENT'} name='Is Student'/>

                    <InputWithLabel onValueChange={val => {
                        if(data && index) setData({ ...data, [index]: {...data[index], hidden: val } })
                    }} type='checkbox' value={item?.hidden} name='Hide'/>
                    <InputWithLabel onValueChange={val => {
                        if(data && index) setData({ ...data, [index]: {...data[index], only_harmony: val } })
                    }} type='checkbox' value={item?.only_harmony} name='Only harmony'/>

                    <Button onClick={e => {
                        delete data[index]
                        setData(data)
                        setIndex(null)
                    }}>Delete</Button>
                </div>              
                <div className={`presentationGroup grow bg-slate-800 flex justify-center basis-full ${item?.type === 'STUDENT' ? 'tiny' : ''}`} style={{opacity: item ? 1.0 : 0.5}}>
                    <PresentationCard data={item}/>
                </div>
            </div> : null}
        </div>
    )
}

/**************************************************************************************************/
/**************************************************************************************************/

const RoomItem = (props) => {
    const { name, onClick } = props
    return (
        <div className='m-1 cursor-pointer text-sm p-2 item bg-slate-200 overflow-hidden rounded-md' onClick={onClick}>
            <b>{name}</b>
        </div>
    )
}

const RoomEditor = (props) => {
    const { data, setData } = props

    const [show, setShow] = useState(false)
    const [index, setIndex] = useState(null)

    const item = (data && index) ? (data[index] || null) : null

    const addNewRoom = () => {
        const uuid = generateUUID()  // Reuse generateUUID function
        setData({ ...data, [uuid]: '' })  // Initialize with empty name
    }

    const deleteAllRooms = () => {
        const newData = { ...data }
        Object.keys(newData).forEach(key => {
            delete newData[key]
        })
        setData(newData)
        setIndex(null)
    }

    return (
        <div className='control_subsection'>
            <FormLabel>
                <Button className='cursor-pointer mb-1 p-1 ph-2 h-6 mr-2' variant='solid' color='warning' onClick={e => setShow(!show)}>
                    <label className='!mt-0 cursor-pointer'>{show ? 'Hide' : 'Show'}</label>
                </Button>
                Rooms
                {
                    show ? <Button className='cursor-pointer mb-1 p-1 h-6 ml-2' variant='solid' loading={!!!data} color='primary' onClick={e => addNewRoom()}>
                        <label className='!mt-0 cursor-pointer'>New</label>
                    </Button> : null
                }
                {
                    show ? <Button className='cursor-pointer mb-1 p-1 h-6 ml-2' variant='solid' loading={!!!data} color='warning' onClick={e => deleteAllRooms()}>
                        <label className='!mt-0 cursor-pointer'>Delete All Rooms</label>
                    </Button> : null
                }
            </FormLabel>
            {
                show ? <div className='flex flex-wrap rounded-lg overflow-hidden'>
                    <div className='presentationList min-w-[20em] max-h-[30em] grow bg-slate-50 overflow-auto p-1'>
                        {!data ? <Spinner /> : <>
                            {Object.keys(data).map(slug =>
                                <RoomItem key={`${slug}_roomitem`} name={data[slug]} onClick={e => setIndex(slug)} />
                            )}
                        </>}
                    </div>
                    <div className='presentationEditor min-w-[20em] grow bg-slate-400 p-3' style={{ opacity: item ? 1.0 : 0.5 }}>
                        <i>slug: <code>{index}</code></i>
                        <InputWithLabel onValueChange={val => {
                            if (data && index) setData({ ...data, [index]: val })
                        }} value={item || ''} name='Room Name' />
                        <Button onClick={e => {
                            const newData = { ...data }
                            delete newData[index]
                            setData(newData)
                            setIndex(null)
                        }}>Delete</Button>
                    </div>
                </div> : null
            }
        </div>
    )
}

/**************************************************************************************************/
/**************************************************************************************************/



const OrganizerItem = (props) => {
    const { person, onClick } = props
    return (<div className='m-1 cursor-pointer text-sm p-2 item bg-slate-200 overflow-hidden rounded-md' onClick={onClick}>
        <b>{person}</b>
    </div>)
}


const OrganizerEditor = (props) => {
    const { data, setData, depends } = props

    const [ show, setShow ] = useState(false)
    const [ index, setIndex ] = useState(null)

    const item = (data && index) ? (data[index] || null) : null

    const addNewOrganizer = () => {
        const uuid = generateUUID()
        setData({ ...data, [uuid]: {
            person: '',
            subtitle: '',
            image: '',
            type: 'ORGANIZER'
        }})
    }
    return (
        <div className='control_subsection'>
            <FormLabel>
                <Button className='cursor-pointer mb-1 p-1 ph-2 h-6 mr-2' variant='solid' color='warning' onClick={e => setShow(!show)}>
                    <label className='!mt-0 cursor-pointer'>{ show ? 'Hide' : 'Show' }</label>
                </Button>
                Organizers
                { 
                    show ? <Button className='cursor-pointer mb-1 p-1 h-6 ml-2' variant='solid' loading={!!!data} color='primary' onClick={e => addNewOrganizer()}>
                        <label className='!mt-0 cursor-pointer'>New</label>
                    </Button> : null
                }
            </FormLabel>
            { show ? <div className='flex flex-wrap rounded-lg overflow-hidden'>
                <div className='presentationList min-w-[20em] max-h-[30em] grow bg-slate-50 overflow-auto p-1'>
                    { !data ? <Spinner/> : <>
                        {Object.keys(data).filter(x => x != 'wah').map(slug => 
                            <OrganizerItem key={`${slug}_presitem`} person={data[slug]?.person} onClick={e => setIndex(slug)}/>
                        )}
                    </> }
                </div>
                <div className='presentationEditor min-w-[20em] grow bg-slate-400 p-3' style={{opacity: item ? 1.0 : 0.5}}>
                    <i>slug: <code>{index}</code></i>
                    <InputWithLabel onValueChange={val => {
                        if(data && index) setData({ ...data, [index]: {...data[index], person: val } })
                    }} value={item?.person || ''} name='Name'/>
                    <InputWithLabel onValueChange={val => {
                        if(data && index) setData({ ...data, [index]: {...data[index], image: val } })
                    }} value={item?.image || ''} name='Image'/>
                    <InputWithLabel onValueChange={val => {
                        if(data && index) setData({ ...data, [index]: {...data[index], subtitle: val } })
                    }} type='multiline' value={item?.subtitle || ''} name='Subtitle'/>
                    <InputWithLabel onValueChange={val => {
                        if(data && index) setData({ ...data, [index]: {...data[index], type: val ? 'OLD_ORGANIZER' : 'ORGANIZER' } })
                    }} type='checkbox' value={item?.type === 'OLD_ORGANIZER'} name='Is historic'/>

                    <InputWithLabel onValueChange={val => {
                        if(data && index) setData({ ...data, [index]: {...data[index], hidden: val } })
                    }} type='checkbox' value={item?.hidden} name='Hide'/>

                    <Button onClick={e => {
                        delete data[index]
                        setData(data)
                        setIndex(null)
                    }}>Delete</Button>
                </div>              
                <div className={`organizers grow bg-slate-800 flex justify-center whiteText basis-full`}>
                    <OrganizerCard data={item}/>
                </div>
            </div> : null}
        </div>
    )
}

/**************************************************************************************************/


const GlobalControlPanel = (props) => {
    const navigate = useNavigate()

    const { auth, storage, database } = useContext(FirebaseContext)
    
    const [ loading, setLoading ] = useState(false)
    const [ isUploading, setUploading ] = useState(false)

    const [ data, setData ] = useState(null)
    const [ presentations, setPresentations ] = useState(null)
    const [ organizers, setOrganizers ] = useState(null)
    const [ rooms, setRooms ] = useState([])

    const [ files, setFiles ] = useState(null)
    const [ error, setError ] = useState(null)

    const user = useContext(UserContext)
    useEffect(() => {if(!user) navigate('/landlord')}, [ navigate, user ])

    const getGlobalSettings = async() => {
        try {
            setData((await (await get(child(dbRef(database), 'global'))).val()))
        }catch(err) { setError(err !== null ? err.toString() : 'null')}
    }

    const getFiles = async() => {
        const listing = await listAll(ref(storage, '2024/'))
        setFiles(
            listing.items.map(x => ({
                href: `https://firebasestorage.googleapis.com/v0/b/${x.bucket}/o/${encodeURIComponent(x.fullPath)}?alt=media`,
                name: x.name
            }))
        )
    }

    const getPresentations = async() => {
        try {
            setPresentations((await (await get(child(dbRef(database), 'presentations'))).val()))
        }catch(err) { setError(err !== null ? err.toString() : 'null')}
    }
    
    const getOrganizers = async() => {
        try {
            setOrganizers((await (await get(child(dbRef(database), 'organizers'))).val()))
        }catch(err) { setError(err !== null ? err.toString() : 'null')}
    }

    const getRooms = async () => {
        try {
            const roomsData = (await (await get(child(dbRef(database), 'rooms'))).val());
            setRooms(roomsData || {});
        } catch (err) { setError(err !== null ? err.toString() : 'null'); }
    };

    useEffect(() => { // man shut ya ass react, dependency missing dumbass
        getGlobalSettings()
        getFiles()
        getPresentations()
        getOrganizers()
        getRooms()
    }, []) // eslint-disable-line

    return (
        <div className='bg-zinc-100 rounded-lg container mx-auto'>
            <div className='flex flex-wrap container p-4 flex-col'>
                <div className='flex justify-between pb-8 items-center'>
                    <h1 className='text-4xl'>control</h1>
                    <div className='flex flex-row items-center space-x-2'>
                        <span className='italic text-sm'>{user ? user.email : 'Not logged in, what the fuck?'}</span>
                        <SimpleButton className='w-8 h-8' children={<IoIosLogOut/>} onClick={e => api.logout(auth)}/>
                    </div>
                </div>

                { (data) ? <div className='space-y-2'>
                    <RegistrationStateSelect depends={{ data, setData }}/>
                    <DateSetSection depends={{ data, setData }}/>
                    <PriceSetSection depends={{ data, setData }}/>

                    <div className='control_subsection'>
                        <FormLabel>Documents</FormLabel>
                        <SimpleInputField name='Registrace' field='registration_url' depends={{ data, setData }} haveOpenBtn={true}/>
                        <SimpleInputField name='Registrace přednášek' field='registration_speaker_url' depends={{ data, setData }} haveOpenBtn={true}/>
                        <SimpleInputField name='Terms or Conditions' field='tos_url' depends={{ data, setData }} haveOpenBtn={true}/>
                        <SimpleInputField name='Rules' field='rules_url' depends={{ data, setData }} haveOpenBtn={true}/>

                        <SimpleInputField name='Pokyny pro účastníky' field='instruction_url' depends={{ data, setData }} haveOpenBtn={true}/>
                        <SimpleInputField name='Harmonogram' field='schedule_url' depends={{ data, setData }} haveOpenBtn={true}/>

                        <SimpleInputField name='Formulář pro nezletilé' field='underage_url' depends={{ data, setData }} haveOpenBtn={true}/>

                        <SimpleInputField name='Mail správce webu' field='admin_mail' depends={{ data, setData}} haveOpenBtn={true}/>
                    </div>

                    <FileBrowser depends={{ setUploading, storage, setFiles, files, loading, isUploading }}/>
                    <PresentationEditor data={presentations} setData={setPresentations} depends={{ rooms }}/>
                    <RoomEditor data={rooms} setData={setRooms}/>
                    <OrganizerEditor data={organizers} setData={setOrganizers}/>
                    <SaveButton depends={{ loading, setLoading, setError, database, data, presentations, organizers, rooms }}/>
                    
                </div> : <div className='space-y-2 flex justify-center items-center flex-col'>
                    <Spinner size='xl'/>
                </div>}

                {((error) ? <ErrorNotification className='mt-4'>{error.toString()}</ErrorNotification> : null)}
            </div>
        </div>
    )
}

/**************************************************************************************************/

const Landlord = (props) => {
    import('./Landlord.scss')

    const [ fireapp, setFireapp ] = useState(null)
    const [ user, setUser ] = useState(false)

    useEffect(() => {
        const fireapp = initializeApp(fireapp_settings)
        const auth = getAuth(fireapp)
        const storage = getStorage(fireapp)
        const database = getDatabase(fireapp)

        setFireapp({ fireapp, auth, storage, database })

        onAuthStateChanged(auth, (user) => setUser(user))
    }, [])

    return (
        <UserContext.Provider value={user}>
            <FirebaseContext.Provider value={fireapp}>
                <VechaiProvider>
                    { (fireapp !== null) ? <div className='flex justify-center items-center py-32'>
                        <Routes>
                            <Route path='/'          element={<Login/>}/>
                            {/* <Route path='people'     element={<PeopleList/>}/> */}
                            {/* <Route path='people/:id' element={<PersonEditor/>}/> */}
                            <Route path='control'    element={<GlobalControlPanel/>}/>
                        </Routes>
                    </div> : <div className='flex justify-center items-center py-32'>
                        <div className='bg-white p-8 rounded-xl'>
                            <Spinner size='xl'/>
                        </div>
                    </div>}
                </VechaiProvider>
            </FirebaseContext.Provider>
        </UserContext.Provider>
    )
}

/**************************************************************************************************/

export default Landlord
