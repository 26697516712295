export const fireapp_settings = {
    apiKey: "AIzaSyDPsJ2BjOTWKqRETVWdXtC24iWrvnfR03Y",
    authDomain: "pinoc-2019.firebaseapp.com",
    databaseURL: "https://pinoc-2019.firebaseio.com",
    projectId: "pinoc-2019",
    storageBucket: "pinoc-2019.appspot.com",
    messagingSenderId: "270957523369",
    appId: "1:270957523369:web:9b4f8e6419a88116"
}

export const text = {
about: `
# O akci

π noc je ostravská přednášková noc pro studenty středních a vysokých škol. Kromě vlastního prezentování si můžete poslechnout zajímavé přednášky ostatních studentů i hostů z akademického prostředí. Rovněž budete mít možnost vyzkoušet si tematické únikové hry, první pomoc, hlavolamy či různorodé pokusy. Zkrátka a dobře, neváhejte a zavítejte **___DATE_VENUE___**, v ___TIME___ na [**Wichterlovo gymnázium**](https://www.google.com/maps/place/Wichterlovo+gymn%C3%A1zium/@49.8257606,18.1658367,17z/data=!3m1!4b1!4m5!3m4!1s0x4713e154d366f4fd:0xcd23e7e1fa456759!8m2!3d49.8257572!4d18.1680254). Bude to opět jedinečné.

Pro přihlášení se s přednáškou je třeba se registrovat do **___DATE_SPEAKER___**. Pro přihlášení se bez přednášky stačí registrace do **___DATE_REGISTER___**.
`,

nextabout: `
# O akci

π noc je ostravská přednášková noc pro studenty středních a vysokých škol. Kromě vlastního prezentování si můžete poslechnout zajímavé přednášky ostatních studentů i hostů z akademického prostředí. Rovněž budete mít možnost vyzkoušet si tematické únikové hry, první pomoc, hlavolamy či různorodé pokusy. Zkrátka a dobře, neváhejte a zavítejte **na podzim roku ___YEAR___** na [**Wichterlovo gymnázium**](https://www.google.com/maps/place/Wichterlovo+gymn%C3%A1zium/@49.8257606,18.1658367,17z/data=!3m1!4b1!4m5!3m4!1s0x4713e154d366f4fd:0xcd23e7e1fa456759!8m2!3d49.8257572!4d18.1680254). Bude to opět jedinečné.

Registrace na další ročník začne už ke konci léta. Veškeré informace ke spuštění registrací najdete na našem [**Instagramu**](https://www.instagram.com/pi_noc/).
`,

mainPresentations: `
# Hlavní přednášky
`,

// Kromě amatérských přednášek budou mít přednášky i pozvaní odborníci, které pro vás usilovně sháníme.

orgDisclaimer: `
Tato akce by se také nemohla uskutečnit bez podpory vedení školy a několika pedagogů.

V případě jakýchkoliv dotazů nás kontaktujte na mail [info@pinoc.cz](mailto:info@pinoc.cz). Moc rádi pomůžeme. Sledovat nás můžete na [Facebooku](https://www.facebook.com/events/518955538916600/) i na [Instagramu](https://www.instagram.com/pi_noc/).

Hlavní organizátoři jsou v průběhu let zařazováni na [tuto stránku](/organizers).
`,
galleryLink: `
Více fotografií od ročníku 2024 najdete na [Zoneramě](https://pinoc.zonerama.com)
`,

old_orgText: `
Tito organizátoři byli hlavní oporou při pořádání Pí nocí. Tímto jim vyjadřujeme velké díky za nervy, čas a promeškané vyučovací hodiny, které této akci obětovali.
`,

entryFee: `
# Vstupné a info k registraci
Vstupné na π noc činí *___PRICE_NORMAL___ Kč*. Nově nelze na akci přijít bez registrace, avšak přes náš portál se budete moct registrovat i v průběhu akce do vyčerpání míst. Počet volných míst je viditelný na registračním webu. Platba bude na místě buď hotově nebo QR kódem.

# S sebou
Spacák, karimatku, fancy pyžamo (v případě že zůstanete přes noc) a především dobrou náladu.

# Možnost občerstvení
Na místě bude možnost si v jídelně zakoupit menší pochutiny a pití. K zakoupení za **50 Kč** bude taktéž vratný kelímek. Platit lze buď hotově nebo QR kódem.
`,

entryFeeOld: `
# Vstupné
Vstupné na π noc přes účet činí *___PRICE_NORMAL___ Kč*. Platbu prosím uhraďte převodem na účet \`162970800/0300\`. Do zprávy pro příjemce napište kód, který jste obdrželi při registraci. V případě, že nezaplatíte přes účet, cena na místě činí *___PRICE_LATE___ Kč*. Tato cena platí i pro předem nezaregistrované účastníky. 

# S sebou
Spacák, karimatku, fancy pyžamo (v případě že zůstanete přes noc) a především dobrou náladu.
`,

conditions: `
### Podmínky
* Účastníci mladší 18 let potřebují pro účast na akci souhlas zákonného zástupce.
* Účastníci mladší 15 let musí navíc přijít v doprovodu dospělého.

[Formulář pro souhlas s účastí na akci pro nezletilé.](___UNDERAGE_URL___)`,

covidMessage:`
Vzhledem k nařízení ohledně šíření koronaviru se páteční π noc ruší. Pokud máš již zaplacené vstupné, v nejbližší době ti přijde zpátky na účet. Nový termín π noci se již teď vybírá. Děkujeme za pochopení.
`
}

export const organizers = [{
        name: 'Samuel Neděla',
        subtitle: 'Naše sluníčko~',
        image: './assets/img/headshots/new_sunday.jpg'
    },
    {
        name: 'Patrick Železný',
        subtitle: 'samozvaný technik',
        image: './assets/img/headshots/iron.jpg',
    },
    {
        name: 'Eva Žurková',
        subtitle: 'del este hasta oeste',
        image: './assets/img/headshots/efkha.jpg',
    },
    {
        name: 'Sára Fildánová',
        subtitle: 'livin da vida loca',
        image: './assets/img/headshots/saya.jpg'
    },
    {
        name: 'Frída Guňková',
        subtitle: 'jsem hroch',
        image: './assets/img/headshots/frida.jpg',
    },
    {
        name: 'Filip Macek',
        subtitle: 'zmenším židle',
        image: './assets/img/headshots/macek.jpg',
    }
]

export const old_organizers = [
    {
        name: 'Lucie Sněhurka Peterková',
        subtitle: 'Chladnokrevná žena',
        image: './assets/img/headshots/yukionna.jpg',
    },
    {
        name: 'Pavel Duong',
        subtitle: [
            'Jack of all trades, master of none',
            'When depression hits you just right~',
            'The god damn weeb who maintains this site.',
            'wah',
            'The Tarnished Yellow Palpatine',
            'takodachi'
        ],
        image: './assets/img/headshots/yellow-palpatine.jpg',
        knobhead: true
    },
    {
        name: 'Petra Pedra Smržová',
        subtitle: 'Umělkyně s alternativními myšlenkami',
        image: './assets/img/headshots/pedra.jpg',
    },
    {
        name: 'Vojta Vovot David',
        subtitle: 'Matematický mág',
        image: './assets/img/headshots/hongkonger.jpg'
    },
    {
        name: 'Jan Gekon Lukovský',
        subtitle: [
            'Sympatický biolog s krabovcem na obličeji',
            'What if I told you, that is not his hand?'
        ],
        image: './assets/img/headshots/viner.jpg',
    },
    {
        name: 'Matěj Vrublík Vrúbel',
        subtitle: 'Sympatický biolog bez krabovce na obličeji',
        image: './assets/img/headshots/yamanosusume.jpg',
    },
    {
        name: 'Jakub Murcek',
        subtitle: 'Hraje lolko a je hodně tilted',
        image: './assets/img/headshots/oh_look_at_me_im_an_anime_protagonist_doing_the_stare_in_the_distance_pose.jpg',
    },{
        name: 'Jiří Mojmír Močkoř',
        subtitle: 'Organizátor, u kterého se zapomnělo, že je organizátor',
        image: './assets/img/headshots/trees.jpg',
    },
    {
        name: 'Kateřina Korunka Ondrušová',
        subtitle: ['Sicilská mafie ❤️', 'アルコールを飲んだ後転んだ'],
        image: './assets/img/headshots/sicilian_mafia.jpg'
    },
    // new faces
    {
        name: 'Václav David',
        subtitle: '┴┬┴┤( ͡° ͜ʖ├┬┴┬',
        image: './assets/img/headshots/basshunter.jpg'
    },{
        name: 'Šimon Černý',
        subtitle: 'Vyznavatel Muskismu',
        image: './assets/img/headshots/smug.jpg'
    }
]
// const constants = { text, organizers, fireapp_settings }
