import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import Eclipse from './pages/Eclipse'
import Landlord from './pages/Landlord'
import WahScreen from './pages/WAH'
import Organizatori from './pages/Organizatori'
import Harmonogram from './pages/harmonogram'
import GenericError from './pages/GenericError'

import * as serviceWorker from './serviceWorker'

ReactDOM.render(<React.StrictMode>
    <Router>
        <Routes>
            <Route path='/' element={<Eclipse/>}/>
            <Route path='wah' element={<WahScreen/>}/>
            <Route path='landlord/*' element={<Landlord/>}/>
            <Route path='organizers' element={<Organizatori/>}/>
            <Route path='harmonogram' element={<Harmonogram/>}/>

            <Route path='*' element={<GenericError/>}/>
        </Routes>
    </Router>
</React.StrictMode>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
