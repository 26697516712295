import React, { Component } from 'react'
import './main.scss'
import Sources from '../GallerySource'

class GalleryScreen extends Component{
    constructor(props){
        super(props)
        this.state = {
            currentlyViewing: null
        }
        this.changeCurrentlyViewing = this.changeCurrentlyViewing.bind(this)
    }
    changeCurrentlyViewing(url){
        this.setState({currentlyViewing: url})
    }
    render(){
        return(
            <div>
                <div className="galleryScreen" ref={this.props.fRef}>
                    <div><h1>Galerie</h1></div>
                    {Object.keys(Sources).map((x,i) => 
                        <Listing name={x} source={Sources[x]} key={`gallery_${x}_${i}`} change={this.changeCurrentlyViewing}/>
                    )}

                    {!this.state.currentlyViewing ||
                    <FullscreenModal
                        change={this.changeCurrentlyViewing}
                        image={this.state.currentlyViewing}/>}
                </div>
            </div>
        )
    }
}

class Listing extends Component{
    constructor(props){
        super(props)
        this.state = {
            currentState: 0, // 0 = nothing, 1 = fetching, 2 = finished
            data: null
        }
        this.loadData = this.loadData.bind(this)
    }
    loadData(){
        if(this.state.currentState !== 0) return
        this.setState({currentState: 1})
        this.props.source().then(data => this.setState({currentState: 2, data: data}))
        .catch(err => this.setState({currentState: 0, data: null}))
        // some magic async shit going on here
        //this.setState({currentState: 2, data: Constants.gallery[this.props.name]})
    }
    render(){
        return (
            <div className="gallerySection">
                <h2>{this.props.name}</h2>
                <div className="galleryListing">
                    {
                        this.state.currentState !== 2 ?
                        <LoadButton onClick={this.loadData}>
                            {this.state.currentState === 0 ? 'Načíst obrázky' : 'Načítání...'}
                        </LoadButton> :
                        this.state.data.map((x,i) => 
                            <GalleryItem key={`gallery_${i}`} data={x} onClick={_ => {
                                window.history.pushState(null, null, '')
                                document.body.classList.add('noscroll')
                                this.props.change(x.image)
                            }}/>
                        )
                    }
                </div>
            </div>
        )
    }
}

class LoadButton extends Component{
    render(){
        return(
            <div className="loadButton" onClick={this.props.onClick}>
                {this.props.children}
            </div>
        )
    }
}

class GalleryItem extends Component{
    render(){
        return(
            <div
                className="galleryItem"
                onClick={this.props.onClick}
                style={{backgroundImage:`url(${this.props.data.thumb || this.props.data.image})`}} // Warning, images can be enormous as my d-
            ></div>
        )
    }
}

class FullscreenModal extends Component{
    constructor(props){
        super(props)
        this.closeMePlease = this.closeMePlease.bind(this)
    }
    componentDidMount(){ window.addEventListener('popstate', this.closeMePlease) }
    componentWillUnmount(){ window.removeEventListener('popstate', this.closeMePlease) }
    closeMePlease(){
        document.body.classList.remove('noscroll')
        this.props.change(null)
    }
    render(){
        return (
            <div className="fullscreenOverlay" style={{backgroundImage:`url(${this.props.image})`}}>
                <div className="closeButton" onClick={_=>this.closeMePlease()}>
                    <div className="icon"></div>
                </div>
            </div>
        )
    }
}

export default React.forwardRef((props,ref) => 
    <GalleryScreen {...props} fRef={ref}>{props.children}</GalleryScreen>
)