import { React, useState } from 'react'

const WahScreen = (props) => {
    const [ code, setCode ] = useState(null)
    const [ attempt, setAttempt ] = useState(0)

    const checkCode = (e) => {
        setCode(e.target.value)

        if(e.target.value.length === 6){
            if(e.target.value === '910846'){
                setAttempt(null)
            }else{
                let newVal = attempt + 1
                setAttempt(newVal)
                setCode('')
                if(newVal >= 3){
                    setCode('too many attempts, well try refreshing i guess, too lazy to do proper security')
                }
            }
        }
    }

    return <div className='wahScreen'>
        <div className='gameScreen'>
            {
                (attempt != null) ? <div>
                    <textarea value={code} disabled={attempt >= 3} onChange={checkCode} maxLength={6} placeholder={`insert code here (${attempt}/3) attempts`}/>
                </div> : <div>
                    <p>...ok I lied a bit, there is a small new thing: I ate LIDL's 50Kč sushi that was a day expired, and guess how it was? It tasted. Didn't taste good nor bad, just tasted.</p>

                    <p>Hey there, I'm surprised to see you here. I'll be honest with you.</p>

                    <p>For you to stumble here, you are either a huge nerd or got here by accident. If it's the latter, well... WASURE NO BEAM</p>

                    <p>If it's the former, then keep reading. This is just a dump of thoughts of the Yellow Palpatine.</p>

                    <p>One of the most special thing about PiNoc from my point of view is that it was ran entirely by students. Teachers were there mostly out of obligation to make sure nothing went haywire or helped us with minor things. And if PiNoc wants to keep going...</p>

                    <p>...then we need another technically-focused folked to take over my job. I mean hell, I'm a freaking alumni. I'm too old for this, my hair's already balding.</p>

                    <p>You might be asking, what does this entail?</p>

                    <p>It's the management of this... entire site. React, Firebase and everything around it. It's not complicated, the main foundation is already here and relatively stable, what you'll most likely be tasked is <i>updating the site when asked</i> and <i>finding a successor</i>, if you so wish. Oh, and I guess you'll have to be from Wigym.</p>

                    <p>If you get this message, meet the Yellow Palpatine on the day or reach out to any of the organizers at that place.</p>
                </div>
            }
        </div>
    </div>
}

export default WahScreen