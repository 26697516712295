import React from 'react'
import { Formik, Form, FieldArray } from 'formik'
import InputField from './InputField'
import './style.scss'
import Markdown from 'react-markdown'
import * as Constants from '../../../constants'

const gdprDocument = 'https://firebasestorage.googleapis.com/v0/b/pinoc-2019.appspot.com/o/documents_2020%2FGDPR_2020.pdf?alt=media'
const Delimiter = () => <div className='delimiter'></div>

const defaultFields = {
    fname: '', lname: '', bday: '', phonenum: '', email: '', consent: false
}
const statuses = [
    '',
    'Probíhá registrace...',
    '💙 Registrace úspěšná!',
    '💔 Chybně zadané nebo chybějící informace.',
    '💔 Registrace selhala!!'
]
const requiredField = 'Toto pole musí být vyplněné.'

export const Conditions = (props) => {
    return (
        <div className='conditions'>
            <Markdown>{
                Constants.text.conditions.split('___').map(field => {
                    if(field === 'UNDERAGE_URL') return props?.url
                    return field
                }).join('')
            }</Markdown>
        </div>
    )
}

function PresentationForm(props){
    return (
        <div className={'presentationField ' + (props.isValid ? 'invalidPresentation' : '')}>
            <InputField disabled={props.disabled} type='text' name={(props.prefix || '') + 'title'}>Název prezentace:</InputField>
            <InputField disabled={props.disabled} component='textarea' name={(props.prefix || '') + 'description'}>Krátká anotace:</InputField>
            <InputField disabled={props.disabled} type='range' name={(props.prefix || '') + 'length'} step='1' max='3' extra={(<div className='visualHelper'><span>25min</span><span>45min</span><span>60min</span><span>90min</span></div>)}>Délka prezentace:</InputField>

            <InputField disabled={props.disabled} type='button' name='removeButton' value='Odstranit' onClick={props.remove}/>
        </div>
    )
}

// 
// To whoever is taking up this source code.
// I apologize. Shit this was written 3 years ago (at the time of writing)
// by *me* and I can safely say this is shite. The only reason I don't want
// to rework is because I have to spend that time elsewhere and this *looks*
// okay. Visually. So... yeah.
// 
// And if this is me, then fuck you.
// 

export function RegistrationForm (){
    return (
        <div className='registrationForm'>
            <Formik initialValues={{...defaultFields, presentation: []}} validate={(values) => {
                const errors = {}

                for(const field in defaultFields) if(!values[field]) errors[field] = requiredField
                if(values.email.match(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i) == null) errors['email'] = 'Neplatná adresa'
                values.presentation.forEach((presentation, index) => {
                    if(!presentation.title) errors['presentation.'+index] = true
                    if(!presentation.description) errors['presentation.'+index] = true
                    if(presentation.length < 0 && presentation.length > 3) errors['presentation.'+index] = true
                })

                return errors
            }} onSubmit={async (values, actions) => {
                // actions.setSubmitting(true)
                // actions.setStatus(1)
                // try{
                //     const リザルト = await PUBLIC.sendRegistration(values)
                //     console.log('サクセス:', リザルト)
                //     actions.setStatus(2)
                // }catch(エラー){ // FUCKING CORS-
                //     console.error('エラー:', エラー)
                //     actions.setStatus(4)
                // }finally{
                //     actions.setSubmitting(false)
                // }

                /*
                    submitData(){
                        if(this.state.state === 1) return
                        if(!this.verifyData()) return this.setState({state: 3})
                        this.setState({state:1})
                        Constants.functions.httpsCallable('register')(this.data).then(リザルト => {
                            console.log('サクセス:', リザルト)
                            this.setState({state: 2})
                        }).catch(エラー => {
                            console.error('エラー:',エラー)
                            this.setState({state: 4})
                        })
                    }
                */
            }}>
                {({ isSubmitting, values, status, isValid, errors }) => {
                    const isDisabled = isSubmitting || status === 2

                    return (
                        <Form>
                            <InputField disabled={isDisabled} name='fname'>Jméno:</InputField>
                            <InputField disabled={isDisabled} name='lname'>Příjmení:</InputField>
                            <InputField disabled={isDisabled} name='bday' type='date'>Datum narození:</InputField>

                            <Delimiter/>

                            <label>Vlastní prezentace:</label><br/>
                            <FieldArray name='presentation' render={helpers => (
                                <>
                                    {values.presentation.map((presentation, index) => (
                                        <PresentationForm isValid={errors['presentation.'+index]} disabled={isDisabled} prefix={`presentation[${index}].`} remove={() => helpers.remove(index)}/>
                                    ))}
                                    <InputField disabled={values.presentation.length >= 2 || isDisabled} type='button' name='addButton' value='Přidat' onClick={() => helpers.push({title: '', description: '', length: 2})}/>
                                </>
                            )}/>

                            <Delimiter/>

                            <InputField disabled={isDisabled} name='email' type='email'>Email:</InputField>
                            <InputField disabled={isDisabled} name='phonenum' type='tel'>Telefonní číslo:</InputField>

                            <Delimiter/>

                            <InputField disabled={isDisabled} name='consent' type='checkbox'>
                                Souhlasím se <a rel='noopener noreferrer' href={gdprDocument} target='_blank'> zpracováním osobních údajů</a>
                            </InputField>

                            <Delimiter/>

                            { /* todo: give the user the ability to reset the form */ }
                            <InputField className='submitContainer' disabled={isDisabled} name='submit' type='submit' value='Registrovat'>
                                <span className='registrationOutput'>
                                    {(() => {
                                        if(isSubmitting) return statuses[1]
                                        if(!isValid) return statuses[3]
                                        if(status) return statuses[status]
                                    })()}
                                </span>
                            </InputField>

                            <Delimiter/>
                            {/* <pre>
                                {JSON.stringify(values, null, 2)}
                                {JSON.stringify(errors, null, 2)}
                            </pre> */}
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}
export default RegistrationForm