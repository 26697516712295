import React, { Component } from 'react'

export function PresentationCard (props) {
    const {
        image,
        name,
        author,
        speaker,
        description,
        imagePosition,
    } = props.data || {}

    return(
        <div className="presentation" style={{backgroundImage: `url(${image})`, backgroundPosition: imagePosition || 'center'}}>
            { name ? <div className="metadata">
                <span className="title">{name}</span>
                <span className="author">{author || speaker}</span>
                <div className="description">{description.split('\n\n').map((x,i) => <p key={`${name}_${author}_paragraph_${i}`}>{x}</p>)}</div>
            </div> : null }
        </div>
    )
}

export default class PresentataionGroup extends Component{
    constructor(props){
        super(props)
        this.state = {
            state: 0, //0 = working, 1 = success, 2 = failed
            data: null,
            error: null
        }
    }
    componentDidMount(){
        /*if(this.props.source)
            this.props.source().then(data => 
                this.setState({state: 1, data})
            ).catch(error => {
                console.error(error)
                this.setState({state: 2, error})
            })*/
    }
    render(){
        return this.props.source ? (
            <div className={`presentationGroup ${this.props.tiny ? 'tiny': ''}`}>
                {/* {
                    (_=>{
                        switch(this.state.state){
                            case 0:
                                return 'Načítání dat...'
                            case 1:
                                return this.state.data.length ? this.state.data.map((x,i) => 
                                    <PresentationCard key={`presentationCard_${i}_${x.title}`} data={x}/>
                                ) : 'Hm? Nic tu není...'
                            case 2:
                                return 'Eeeh? Něco se pokazilo... Fuee~'
                            default:
                                return 'なんてこった?!'
                        }
                    })()
                } */}
                {this.props.source.map((x, i) => 
                    (x !== null ? <PresentationCard key={`presentationCard_${i}_${x.name}`} data={x}/> : x)
                )}
            </div>
        ) : null
    }
}